import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import metteHeader from "../../images/mette.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openMette() {
  window.open("https://medieteknik.nu/sektionen", "_blank")
}

const mettePage = () => (
  <Main>
    <SEO title="Mette" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-Mette" src={metteHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">METTE</p>
          <p class="project-header-text-grey">SECRETARY</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Employment</p>
        <p class="project-bulletpoint-text"> &#9642; Non-profit</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2018-05-10</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          Mette is an association for everyone who identifies as a girl or
          non-binary and studies Media Technology at Linköping University,
          Norrköping campus. They work to enhance the relations between all
          girls and non-binaries on the MT program.
          <br />
          <br />
          I was a part of Mette year 17/18 and had the role of a secretary.
          Together with the other group members, we organized various fun
          activities, some were only for girls and non-binary while other
          activities were for everyone who studies MT.
          <br />
          <br />
          You can checkout their{" "}
          <button class="project-text-link-style" onClick={() => openMette()}>
            webpage
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.WORK}>
          &#8592; Back to work
        </a>
      </div>
    </div>
  </Main>
)

export default mettePage
